import React from 'react';
import { Link } from "gatsby"

import pic from '../assets/images/website-icon.png';

const Success = props => (
    <div id="merci" className="merci">
        <span className="image">
          <img src={pic} alt="" />
        </span>
        <h3>
          Merci, votre message a bien été pris en compte.
          <br/>
          Je reviens vers vous au plus vite.
          <br/>
          <Link to="/">Retour</Link>
        </h3>
    </div>
);

export default Success;